
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import CFormSelect from "@/components/form/Select.vue";
import CFormInputText from "@/components/form/InputText.vue";

import { useFilter } from "@/libs/utils";

const VSpecialistFilter = defineComponent({
  name: "VSpecialistFilter",
  components: { CFormInputText, CFormSelect },
  setup() {
    useHead({ title: "Filtrar especialistas | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { hasAnyFilters, hasSpecificFilter } = useFilter();

    const userEstablishments = computed(() => store.state.user.current?.estabelecimentos || []);
    const filters = reactive({
      nm_especialista: route.query.nm_especialista || undefined,
      cd_estabelecimento:
        Number(route.query.cd_estabelecimento || localStorage.getItem("CD_ESTABELECIMENTO")) || undefined,
    });
    const loading = reactive({ submit: false });

    async function applyFilters() {
      router.replace({ query: { ...route.query, ...filters } });

      loading.submit = true;
      await store.dispatch("searchSpecialists", { search: filters, isPrimary: true });
      loading.submit = false;
    }

    function resetFilters() {
      filters.nm_especialista = undefined;
      filters.cd_estabelecimento = undefined;

      applyFilters();
    }

    function handleClose() {
      router.replace({ name: "specialist-list", query: { ...route.query, close: undefined } });
    }

    if (hasAnyFilters.value) applyFilters();
    if (hasSpecificFilter(["close"])) handleClose();

    return { filters, loading, userEstablishments, applyFilters, resetFilters, handleClose };
  },
});

export default VSpecialistFilter;
